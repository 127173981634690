/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getSchedule($filter: SchedulesFilter) {
    schedules(filter: $filter) {
      id
      sendAt
      sendOn
      customEndDate
      repeatInterval
      recurrenceType
      category {
        id
        name
        colorIndex
      }
      accounts {
        id
        name
        nickname
        provider
        image
      }
    }
    scheduleSuggestions {
      id
      sendOn
      sendAt
      localWeekOffset
      platforms
      accounts {
        id
        name
        nickname
        provider
        image
      }
    }
    accounts {
      id
      name
      nickname
      image
      provider
      platform
      displayName
      description
    }
    categories {
      id
      name
      colorIndex
    }
    user {
      id
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
    company {
      id
      queueStatus
      totalApprovedContents
      scheduleLimitReached
      timeZone
      subscriptionPlan {
        id
        productName
      }
    }
  }
`;
