/* @flow */
import React from "react";
import get from "lodash/get";
import moment from "moment";
import { LoadingIcon } from "icons";

import { useNewUICached } from "util";
import AlarmClockIcon from "components/common/images/alarm-clock.svg";

import { DAYS } from "constants";
import Timeslots from "../Timeslots";

import styles from "./index.css";
import {
  ScrollbarWrapper,
  Wrapper,
  DaysHeader,
  ScheduleWrapper,
  HeaderItem,
  HourRowWrapper,
  HourHeader,
  HourChildren,
  HourBorder,
  DayWrapper,
  MinuteRow
} from "./styles";

// All hours of the day using 0-indexed 24 hour time (0-23)
// ordered such that the day begins at 5am and ends it at 4am
const HOURS = Array.from({ length: 24 }, (_, i) => (i + 5) % 24);

import { buildDesktopCalendarData } from "../util.js";
import { type Props } from "../index.js";

const filledDays = {
  sunday: 0,
  monday: 0,
  tuesday: 0,
  wednesday: 0,
  thursday: 0,
  friday: 0,
  saturday: 0
};

const hourHeight = 47;

const HourRow = ({ hour, children }) => (
  <HourRowWrapper>
    <HourHeader hourHeight={hourHeight}>
      <span>
        {moment
          .utc()
          .set({ hour })
          .set({ minute: 0 })
          .format("h:mm a")}
      </span>
    </HourHeader>
    <HourChildren>
      <HourBorder hourHeight={hourHeight} />
      {children}
    </HourChildren>
  </HourRowWrapper>
);

const Day = ({ children, onClick, day, hour, minute, current }) => (
  <DayWrapper
    className={styles.day}
    onClick={onClick}
    data-day={day}
    hour={hour}
    data-hour={hour}
    data-minute={minute}
    current={current}
  >
    {children}
  </DayWrapper>
);

const DesktopCalendar = ({
  schedules,
  onClickNewTimeslot,
  onClickEditTimeslot,
  focusedTimeslotIds,
  currentWeek
}: Props) => {
  const useNewUI = useNewUICached();

  const todayDate = moment();
  const currentDate = currentWeek.clone();
  const currentWeekday = moment().weekday();

  const data = buildDesktopCalendarData(schedules, currentDate.clone().weekday(0).format('YYYY-MM-D'));

  return (
    <ScrollbarWrapper className={useNewUI ? "new-ui" : "old-ui"}>
      <Wrapper className={useNewUI ? "new-ui" : "old-ui"}>
        <DaysHeader className={useNewUI ? "new-ui" : "old-ui"}>
          <HeaderItem>
            <img src={AlarmClockIcon} alt="alarm clock " />
          </HeaderItem>
          {DAYS.map((d, index) => {
            const dayStatus = todayDate.isBefore(
              currentDate.clone().add(index, "days")
            )
              ? null
              : index === currentWeekday &&
                currentWeek
                  .clone()
                  .startOf("week")
                  .isSame(moment().startOf("week"))
                ? "current"
                : todayDate.isAfter(currentDate.clone().add(index, "days"))
                  ? "disabled"
                  : index < currentWeekday
                    ? "disabled"
                    : null;

            return (
              <HeaderItem key={d} className={dayStatus} status={dayStatus}>
                <div>
                  {d}{" "}
                  <span>
                    {currentDate
                      .clone()
                      .startOf("week")
                      .add(index, "days")
                      .format("MMMM")}{" "}
                    {currentDate
                      .clone()
                      .weekday(index)
                      .format("D")}
                  </span>
                </div>
              </HeaderItem>
            );
          })}
        </DaysHeader>

        <ScheduleWrapper>
          {data ? (
            HOURS.map(hour => (
              <HourRow key={hour} hour={hour}>
                {Object.keys(get(data, hour, [null])).map(minute => (
                  <MinuteRow key={`${hour}-${minute}`}>
                    {DAYS.map((day, index) => {
                      const scheduleData = get(data, [hour, minute, day], []);
                      if (!!scheduleData.length) {
                        scheduleData.map(schedule => {
                          filledDays[schedule.sendOn.toString().toLowerCase()] =
                            filledDays[
                            schedule.sendOn.toString().toLowerCase()
                            ] + 1;
                        });
                      }

                      return (
                        <Day
                          key={`${hour}-${minute}-${day}`}
                          day={day}
                          hour={hour}
                          minute={minute}
                          onClick={onClickNewTimeslot.bind(
                            null,
                            day,
                            hour,
                            minute
                          )}
                          current={
                            index === currentWeekday &&
                            currentWeek
                              .clone()
                              .startOf("week")
                              .isSame(moment().startOf("week"))
                          }
                        >
                          <Timeslots
                            hour={hour}
                            minute={minute}
                            schedules={get(data, [hour, minute, day], [])}
                            focusedTimeslotIds={focusedTimeslotIds}
                            onClickTimeslot={onClickEditTimeslot}
                            filledDays={filledDays}
                          />
                        </Day>
                      );
                    })}
                  </MinuteRow>
                ))}
              </HourRow>
            ))
          ) : (
            <div className={styles.loading}>
              <LoadingIcon />
            </div>
          )}
        </ScheduleWrapper>
      </Wrapper>
    </ScrollbarWrapper>
  );
};

export default DesktopCalendar;
