/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation deleteSchedule($id: ID!, $type: String!) {
    deleteSchedule(id: $id, type:$type) {
      schedule {
        id
      }
      timeslots{
        id
      }
      company {
        id
        scheduleLimitReached
      }
      errors
    }
  }
`;
