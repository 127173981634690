import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import { zipObject } from "lodash";
import { DAYS, SHORT_DAYS } from "constants";
import { Footer, Wrapper, Header, LeftWrapper, Body } from "../styles";
import DropDown from "layout/DropDown";
import {
  Day,
  Days,
  DropDownContent,
  DropDownWrapper,
  Item,
  Wrapper as SendAtWrapper
} from "../SendAt/styles";
import scheduleStyles from "../index.css";
import styles from "./index.css";
import { Value } from "../SendOn/styles";
import { useState } from "react";
import useCustomDateModal from "./use";

const SHORT_TO_LONG_DAYS = zipObject(SHORT_DAYS, DAYS);

const CustomModalFooter = ({ onCancel, onSubmit }) => (
  <Footer>
    {onSubmit ? (
      <Button onClick={onSubmit} type="primary">
        Save
      </Button>
    ) : null}
    <div className={scheduleStyles.secondaryActions}>
      {onCancel ? (
        <Button onClick={onCancel} type="secondary">
          Cancel
        </Button>
      ) : null}
    </div>
  </Footer>
);

const getFormattedFutureDate = () => {
  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + 7);

  const year = futureDate.getFullYear();
  const month = String(futureDate.getMonth() + 1).padStart(2, "0"); // Add 1 to get 1-based month and pad with 0 if necessary
  const day = String(futureDate.getDate()).padStart(2, "0"); // Pad day with 0 if necessary

  return `${year}-${month}-${day}`;
};

const REPEAT_OPTIONS = [
  { label: "Week", label_s: "Weeks", value: "weekly" },
  { label: "Day", label_s: "Days", value: "daily" }
];

const CustomDateModal = ({
  closeModal,
  resetDropdownValue,
  selectedDays,
  onChangeDay,
  customOptionData,
  setCustomOptionData,
  repeatType,
  setRepeatType
}) => {
  const [numberOfRepeats, setNumberOfRepeats] = useState(1);
  const [repeatDropdown, setRepeatDropdown] = useState(
    customOptionData?.repeatType ?? "weekly"
  );
  const [endsValue, setEndsValue] = useState(
    customOptionData?.endsOn ?? "never"
  );
  const [onDate, setOnDate] = useState(
    customOptionData?.date ?? getFormattedFutureDate()
  );
  const [customSelectedDays, setCustomSelectedDays] = useState(
    new Set(selectedDays)
  );
  const { showDropdown, dropdownVisible, hideDropdown } = useCustomDateModal();

  function onCustomChangeDay(day, checked) {
    if (checked) {
      customSelectedDays.add(day);
      setCustomSelectedDays(new Set(customSelectedDays));
    } else {
      customSelectedDays.delete(day);
      setCustomSelectedDays(new Set(customSelectedDays));
    }
  }

  const handleSubmit = () => {
    setRepeatType(repeatDropdown);

    // update the state of selected days in schedule modal
    const daysRemoved = new Set(
      [...selectedDays].filter(item => !customSelectedDays.has(item))
    );
    customSelectedDays.forEach(d => {
      onChangeDay(d, true);
    });
    daysRemoved.forEach(d => {
      onChangeDay(d, false);
    });

    // update the state of custom option data in schedule modal
    setCustomOptionData({
      repeatNumber: numberOfRepeats,
      endsOn: endsValue,
      date: endsValue === "never" ? null : onDate,
      repeatType: repeatDropdown
    });
    closeModal();
  };

  const onClose = () => {
    closeModal();
    resetDropdownValue();
  };

  const getRepeatOptionByValue = value => {
    return REPEAT_OPTIONS.find(option => option.value === value);
  };

  const startAtMoment = moment(onDate.substring(0, 10));

  function handleChange(date: string) {
    setOnDate(moment(date).format("YYYY-MM-DD"));
  }

  return (
    <Modal
      size="small"
      onClose={onClose}
      newFooter={
        <CustomModalFooter onCancel={onClose} onSubmit={handleSubmit} />
      }
      className={styles.ml0}
      overlayClassName={styles.h100}
    >
      <Wrapper>
        <Header>
          <h3>Custom recurrence</h3>
        </Header>
        <Body className={styles.body}>
          {/* Repeat every section */}
          <section className={`${styles.gap8} ${styles.itemsCenter}`}>
            <p className={styles.repeatText}>Repeat every</p>
            <input
              type="number"
              value={numberOfRepeats}
              onChange={e => setNumberOfRepeats(Number(e.target.value))}
              min={1}
              className={styles.numInput}
            />
            <SendAtWrapper className={styles.nml8}>
              <Value onClick={showDropdown}>
                {numberOfRepeats > 1 ? getRepeatOptionByValue(repeatDropdown)?.label_s : getRepeatOptionByValue(repeatDropdown)?.label}
              </Value>

              <DropDownWrapper>
                <DropDown
                  open={dropdownVisible}
                  onClose={hideDropdown}
                  omitDefaultStyle
                >
                  <DropDownContent id="sendAtDropdown">
                    {REPEAT_OPTIONS.map(option => (
                      <Item
                        key={option.value}
                        onClick={() => {
                          setRepeatDropdown(option.value);
                          if (option.value === "daily") {
                            Object.keys(SHORT_TO_LONG_DAYS).forEach(d => {
                              onCustomChangeDay(SHORT_TO_LONG_DAYS[d], true);
                            });
                          } else {
                            const today = moment().format("dddd");
                            Object.keys(SHORT_TO_LONG_DAYS).forEach(d => {
                              onCustomChangeDay(
                                SHORT_TO_LONG_DAYS[d],
                                SHORT_TO_LONG_DAYS[d] === today
                              );
                            });
                          }
                        }}
                      >
                        {numberOfRepeats > 1 ? option.label_s : option.label}
                      </Item>
                    ))}
                  </DropDownContent>
                </DropDown>
              </DropDownWrapper>
            </SendAtWrapper>
          </section>

          {/* Repeat on section */}

          {repeatDropdown === "weekly" && (
            <section className={styles.repeatSection}>
              <p className={styles.textBlack}>Repeat On</p>
              <Days className={styles.nml2}>
                {SHORT_DAYS.map(d => (
                  <Day
                    key={d}
                    className={
                      customSelectedDays.has(SHORT_TO_LONG_DAYS[d])
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      onCustomChangeDay(
                        SHORT_TO_LONG_DAYS[d],
                        customSelectedDays.size === 1
                          ? true
                          : !customSelectedDays.has(SHORT_TO_LONG_DAYS[d])
                      );
                    }}
                  >
                    {d.substring(0, 1)}
                  </Day>
                ))}
              </Days>
            </section>
          )}

          {/* Ends section */}
          <section className={styles.endSection}>
            <p className={styles.textBlack}>Ends</p>
            <div className={styles.gap8}>
              <div
                className={styles.customRadio}
                onClick={() => setEndsValue("never")}
              >
                <span
                  className={
                    endsValue === "never"
                      ? `${styles.radioBtn} ${styles.checked}`
                      : styles.radioBtn
                  }
                />
              </div>
              <label htmlFor="never" className={styles.textBlack}>
                Never
              </label>
            </div>
            <div className={styles.gap40}>
              <div className={`${styles.gap8} ${styles.itemsCenter}`}>
                <div
                  className={`${styles.customRadio} ${styles.onOption}`}
                  onClick={() => setEndsValue("on")}
                >
                  <span
                    className={
                      endsValue === "on"
                        ? `${styles.radioBtn} ${styles.checked}`
                        : styles.radioBtn
                    }
                  />
                </div>
                <label htmlFor="on" className={styles.textBlack}>
                  On
                </label>
              </div>

              <DatePicker
                customInput={<Value>{startAtMoment.format("dddd, MMM D")}</Value>}
                selected={startAtMoment.clone()}
                minDate={moment().toDate()}
                autoComplete="off"
                disabled={endsValue === "never"}
                popperPlacement="bottom"
                className={endsValue === "never" ? styles.datePicker : ""}
                onChange={handleChange}
              />
            </div>
          </section>
        </Body>
      </Wrapper>
    </Modal>
  );
};

export default CustomDateModal;
